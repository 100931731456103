<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          
          <!-- <CTabs  variant="pills" :active-tab="0">
              <CTab title="PENYEBAB KEMATIAN">
                <KetPenyebabKematian />
              </CTab>
              <CTab title="LAPORAN KEMATIAN">
                <KetLapKematian />
              </CTab>
          </CTabs> -->

          
          <b-tabs pills lazy>
            <b-tab title="KETERANGAN KEMATIAN" active>
              <KetPenyebabKematian />
            </b-tab>
            <b-tab title="LAPORAN KEMATIAN">
              <KetLapKematian />
            </b-tab>
          </b-tabs>
          
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import KetPenyebabKematian from "../pasien_meninggal/ket_penyebab_kematian.vue";
import KetLapKematian from "../pasien_meninggal/ket_laporan_kematian.vue";
export default {
  components: {
    KetPenyebabKematian,
    KetLapKematian
  },
  name: "pasien_meninggal",
  data() {
    return {
      
    };
  },
  computed: {
    
  },
  mounted() {

  },
  methods: {

  },
};
</script>
