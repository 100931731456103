<template>
  <div>
    <div style="width: 100%; position: relative">
      <b-button
        variant="success"
        style="position: absolute; right: 0; top: -38px; z-index: 10"
        @click="pindahInput()"
        >Buat Baru</b-button
      >
      <b-card>
        <b-row>
          <b-col cols="12" sm="12" md="3" lg="3">
            <label>ID </label>
            <b-form-input type="text" v-model="form_search.nik_alm"></b-form-input>
          </b-col>

          <b-col cols="12" sm="12" md="3" lg="3">
            <label>Tanggal</label>
            <DatePicker
              v-model="form_search.tanggal_meninggal_alm"
              format="YYYY-MM-DD"
              type="date"
              placeholder="-- Pilih tanggal --"
              style="width: 100%;"
            ></DatePicker>
          </b-col>

          <b-col cols="12" sm="12" md="4" lg="4">
            <label>Nama Pasien</label>
            <b-form-input type="text" v-model="form_search.nama_alm"></b-form-input>
          </b-col>

          <b-col cols="12" sm="12" md="2" lg="2">
            <div
              style="
                display: flex;
                justify-content: flex-end;
                height: 58px;
                align-items: flex-end;
              "
            >
              <b-button variant="light" class="mr-2" @click="resetSearch()">Reset</b-button>
              <b-button variant="primary" @click="searchPasien()">Cari</b-button>
            </div>
          </b-col>
        </b-row>

        <b-row style="margin-top: 30px">
          <b-col md="3">
            <b-form-group
              label="Per Halaman"
              label-for="per-page-select"
              label-cols-md="6"
              label-align-md="left"
              label-size="md"
              style="background-color: "
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="md"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="6" offset-md="3">
            <b-form-group
              label="Cari"
              label-for="filter-input"
              label-cols-md="3"
              label-align-md="right"
              label-size="md"
            >
              <b-input-group size="md">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Ketik disini untuk mencari ..."
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger"
                    >Hapus</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              responsive
              show-empty
              @filtered="onFiltered"
              bordered
              striped
              hover
              :busy="tableBusyLaporanMeninggal"
            >
              <template #cell(actions)="item">
                <b-button
                  variant="warning"
                  class="mr-1"
                  v-c-tooltip.hover.click="'Edit Data'"
                  v-b-modal.modal-edit-asuransi
                  @click="is_data = item.item"
                  size="sm"
                  ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                >

                <b-button
                  variant="danger"
                  class="mr-1"
                  v-c-tooltip.hover.click="'Hapus Data'"
                  v-b-modal.modal-delete-asuransi
                  @click="is_data = item.item"
                  size="sm"
                  ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                >
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="5" offset-md="7">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ket_penyebab_kematian",
  data() {
    return {
      fields: [
        {
          key: "no",
          label: "No.",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "no_surat_meninggal",
          label: "No. Surat",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nik_alm",
          label: "NIK",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_alm",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenis_kelamin_alm",
          label: "Jenis Kelamin",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val == "P" ? "Perempuan" : val == "L" ? "Laki-laki" : "-"
          }
        },
        {
          key: "alamat_alm",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_kelurahan",
          label: "Desa / Kelurahan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_dusun_alm",
          label: "Dusun",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "data_pelapor.tanggal_meninggal",
          label: "Tanggal Meninggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? this.$moment(val).format("DD-MM-YYYY") : "-"
          }
        },
        {
          key: "data_pelapor.jam_meninggal",
          label: "Jam Meninggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? this.$moment(val).format("HH:mm") : "-"
          }
        },

        {
          key: "data_pelapor.lokasi_meninggal",
          label: "Lokasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          formatter: (val) => {
            return val ? val : "-"
          }
        },

        {
          key: "data_pelapor.nama_pelapor",
          label: "Pelapor",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-4 text-center",
        // },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusyLaporanMeninggal: false,
      nama_file: "laporan_meninggal",
      
      form_search: {
        nik_alm: null,
        tanggal_meninggal_alm: null,
        nama_alm: null
      }
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  activated() {
    this.totalRows = this.items.length;
    this.getDatas()
  },
  methods: {
    pindahInput() {
      let vm = this;
      vm.$router.push({
        path: "/pasien/input_laporan_kematian",
        // query: { id_ba : data.item.id },
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this
      try {
        this.tableBusyLaporanMeninggal = true;
        let res = await this.$axios.post(`${vm.nama_file}/list`);
        console.log(vm.nama_file, res);
        if (res.data.status === 200 && res.data.message === "sukses") {
          this.items = res.data.data;
          for (let i = 0; i < this.items.length; i++) {
            let x = this.items[i];
            x.no = i + 1;
          }
          this.totalRows = this.items.length;
        } else {
          throw res.data;
        }
      } catch (err) {
        console.log(err);
        if (err.message) {
          this.triggerAlert({
            variant: "danger",
            msg: err.message.toUpperCase(),
            showing: true,
          });
        } else {
          this.triggerAlert({
            variant: "danger",
            msg: err.toUpperCase(),
            showing: true,
          });
        }
      } finally {
        this.tableBusyLaporanMeninggal = false;
      }
    },
    async searchPasien() {
      const vm = this;
      if (vm.form_search.tanggal_meninggal_alm) {
        vm.form_search.tanggal_meninggal_alm = vm.$moment(vm.form_search.tanggal_meninggal_alm).format("YYYY-MM-DD")
      }
      try {
        this.tableBusyPenyebabKematian = true;
        let res = await vm.$axios.post(`${vm.nama_file}/list`, vm.form_search)
        console.log(res.data);
        if (res.data.status === 200 && res.data.message === "sukses") {
          this.items = res.data.data;
          for (let i = 0; i < this.items.length; i++) {
            let x = this.items[i];
            x.no = i + 1;
          }
          this.totalRows = this.items.length;
        } else {
          this.triggerAlert({
            variant: "danger",
            msg: (res.data.message).toUpperCase(),
            showing: true,
          });
        }
      } catch (err) {
        this.triggerAlert({
            variant: "danger",
            msg: "TERJADI GANGGUAN JARINGAN",
            showing: true,
          });
      } finally {
        this.tableBusyPenyebabKematian = false;
      }
    },
    resetSearch() {
      this.form_search = {
        nik_alm: null,
        tanggal_meninggal_alm: null,
        nama_alm: null
      }
      this.getDatas()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
